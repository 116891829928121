html,
body,
main,
#root {
  height: 100% !important;
}

main>.container-fluid {
  padding: 80px 15px 0;
}

.foto-wrapper {
  text-align: center;
}

.foto-container {
  max-width: 1200px;
  margin   : auto;
  display  : inline-block;
}

.frame-container {
  width     : 100%;
  max-width : 1200px;
  text-align: center;
  margin    : auto;
}

.foto-box {
  position        : relative;
  background-color: #ccc;
  border          : 1px solid #666;
  border-radius   : .25rem;
}

.foto-large {
  height        : 0;
  max-height    : 720px;
  padding-bottom: 40%
}

.foto-small {
  min-height: 150px;
}

.foto-content {
  width          : 100%;
  height         : 100%;
  position       : absolute;
  left           : 0;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  font-size      : 14px;
}

.foto-container>.btn {
  position     : absolute;
  bottom       : 0;
  left         : 0;
  border-radius: 0 0 .2rem .2rem;
}

.react-html5-camera-photo>video {
  width        : 100%;
  max-width    : 1200px;
  border-radius: .25rem;
}

/********************/
/* Lista Atividades */
/********************/
.foto-lista {
  width: 100%;
}

.foto-lista>div {
  width              : 100%;
  padding-top        : 100%;
  overflow           : hidden;
  background-size    : cover !important;
  background-position: center !important;
  background-repeat  : no-repeat;
  border-radius      : .2rem;
}

.foto-lista .btn {
  position        : absolute;
  bottom          : 0;
  right           : 0;
  border          : 0;
  text-align      : right;
  border-radius   : .2rem 0 .2rem 0;
  background-color: rgba(256, 256, 256, .2);
}

.foto-lista .btn:hover {
  background-color: rgba(256, 256, 256, .6);
}

/******************/
/* Foto Atividade */
/******************/

/*******************/
/* Navbar          */
/*******************/
nav .navbar-collapse,
.dropdown-header,
.dropdown-item {
  font-size: 13px;
}

.submenu .dropdown-item {
  padding-top   : 8px;
  padding-bottom: 8px;
}

.page-title {
  text-overflow : ellipsis;
  /*overflow    : hidden;*/
  white-space   : nowrap;
  line-height   : 1;
  border-bottom : 1px solid #dee2e6;
  padding-bottom: 8px;
  margin-bottom : 0;
}

.badge-bigger {
  padding: .25em .4em .4em .4em;
  font-size: 100%;
}

.underlined {
  border-bottom : 1px solid #dee2e6;
  padding-bottom: 0;
  margin-top    : 12px;
  margin-bottom : 0;
}

.empty {
  line-height: 120px !important;
  text-align : center !important;
  background : #fafafa;
}

.bold {
  font-weight: 700 !important;
}

.nowrap {
  white-space: nowrap !important;
}

.footer {
  background-color: #F8F8F8;
  border-top      : 1px solid #E7E7E7;
  text-align      : center;
  padding         : 15px;
  position        : fixed;
  left            : 0;
  bottom          : 0;
  height          : 60px;
  width           : 100%;
}

/*******************/
/* Buttons         */
/*******************/
.btn-icon {
  width         : 40px !important;
  height        : 40px !important;
  padding       : .25rem;
  vertical-align: middle;
  text-align    : center;
  border-radius : 100%;
}

.btn-icon-lg {
  width         : 50px !important;
  height        : 50px !important;
  padding       : .25rem;
  vertical-align: middle;
  text-align    : center;
  border-radius : 100%;
}

.btn-icon-sm {
  width        : 30px !important;
  height       : 30px !important;
  padding      : .25rem;
  font-size    : .875rem;
  line-height  : 1.5;
  border-radius: .2rem;
}

.btn-xs {
  padding  : 0 .3rem;
  font-size: .85rem;
}

/*******************/
/* Badge           */
/*******************/
.badge-md {
  font-size: 100%;
}

.badge-lg {
  font-size: 120%;
}

/*******************/
/* Tables          */
/*******************/
.table-data {
  margin-bottom: 0;
}

.table-data th,
.table-data td {
  padding       : 5px !important;
  white-space   : nowrap !important;
  vertical-align: middle !important;
  font-size     : 13px !important;
}

.table-data th .btn-link {
  color      : #495057;
  font-weight: bold;
  font-size  : 13px;
  padding    : 0;
}

.table-chamada th,
.table-chamada td {
  text-align    : center;
  min-width     : 30px !important;
  vertical-align: middle !important;
  font-size     : 13px !important;
}

.table-chamada th {
  padding: 1px 2px 2px 2px;
}

.table-chamada td {
  padding: 4px 2px;
}

.table-chamada td .form-check-input {
  font-size : 16px !important;
  margin-top: 0;
}

.table-chamada td:first-child {
  padding-left : 8px;
  padding-right: 8px;
  font-weight  : 600;
  text-align   : left;
  white-space  : nowrap;
}

.table-chamada td:only-child {
  text-align: center !important;
}

.table-today {
  border: 2px solid #e12132 !important;
}

/*******************/
/* Card Filter     */
/*******************/
.card-filter {
  background-color: #F8F9F4 !important;

}

.card-filter .card-title {
  margin-bottom: .25rem;
  font-size    : 16px;
  font-weight  : 700;
  line-height  : 1.2;
}

.card-filter .card-body {
  padding: .25rem .5rem .5rem .5rem !important;
}

@media (min-width: 576px) {
  .card-filter .card-body {
    padding-bottom: 0 !important;
  }
}

.card-filter .input-group {
  margin-bottom: .4rem !important;
}

.card-filter .input-group-text {
  font-weight: 500;
  font-size  : 12px !important;
}

/*
@media (max-width: 575px) {
  .card-filter .input-group-text {
    width: 55px !important;
  }
}
*/

/*******************/
/* Forms           */
/*******************/
.form-sm label,
.form-sm .form-check-label,
.form-sm .form-control,
.form-sm .custom-select,
.ferm-sm .btn {
  font-size: 14px;
}

.form-sm .form-control {
  padding: .375rem .4rem;
}

.form-sm .custom-select {
  padding: .375rem 1.2rem .375rem .4rem;
}

.form-sm .btn {
  line-height: 1.37;
}

.col-form-label {
  font-weight: 500;
}

@media (min-width: 576px) {
  .col-form-label {
    text-align: right;
  }
}

.autosize {
  width: auto;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.invalid-feedback {
  margin-top: 3px;
  font-size : 11px;
}

.form-horizontal .form-label {
  margin-bottom: 0px;
  font-size    : 12px;
  font-weight  : 600;
}

.form-horizontal .form-group {
  margin-bottom: 0px;
}

/*******************/
/* Loading Overlay */
/*******************/
.loading {
  height: 100%;
}

.loading-overlay {
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100%;
  opacity         : 0.80;
  filter          : alpha(opacity=80);
  -moz-opacity    : 0.8;
  background-color: #ffffff;
  text-align      : center;
  z-index         : 100;
}

.loading-overlay div {
  position: absolute;
  top     : 50%;
}

/*******************/
/* Pagination      */
/*******************/
.pagination {
  justify-content: center;
}

/*******************/
/* Custom Form     */
/*******************/
.form-custom .form-control,
.form-custom .custom-select,
.form-custom .input-group-text {
  border-left: 4px solid #3d83b7;
}

.form-custom .form-control:required,
.form-custom .custom-select:required,
.required .form-control {
  border-left: 4px solid #e12132;
}

.form-custom .input-group>.form-control {
  border-left: 1px solid #ced4da;
}

/*******************/
/* Datepicker      */
/*******************/
.input-group .react-datepicker-wrapper {
  flex: 1;
}

.form-group .react-datepicker-wrapper {
  width: 100%;
}

.center-triangle .react-datepicker__triangle {
  left: 120px;
}

.right-triangle .react-datepicker__triangle {
  left: 200px;
}

/*******************/
/* Typeahead       */
/*******************/
.typeahead {
  position : relative;
  display  : block;
  flex     : 1 1;
  min-width: 0;
  width    : 100%;
}

.typeahead ul {
  position        : absolute;
  display         : block;
  max-height      : 300px;
  margin          : 0;
  padding         : 5px 0;
  overflow        : auto;
  will-change     : transform;
  top             : 0;
  left            : 0;
  transform       : translate3d(0px, 35px, 0px);
  z-index         : 1000;
  list-style      : none;
  background-color: #fff;
  background-clip : padding-box;
  border          : 1px solid rgba(0, 0, 0, .15);
  border-radius   : .25rem;
  width           : 100%;
  color           : #212529;
  font-size       : .85rem;
  box-shadow      : 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.typeahead ul>li {
  padding    : .25rem 1rem;
  cursor     : pointer;
  white-space: nowrap;

}

.typeahead ul>li:hover {
  background-color: #eee;
}

/*******************/
/* Dashboard       */
/*******************/

.alert-total {
  margin-bottom: .5rem !important;
}

.alert-total h6 {
  margin-bottom: 0;
}

.alert-total div {
  text-align : right;
  font-size  : 4.5rem;
  font-weight: bold !important;
  line-height: .8;
}

/*******************/
/* Calendar Month  */
/*******************/

.table-month {
  table-layout: fixed;
  min-width   : 1000px;
}

.table-month thead th,
.table-month tfoot th {
  padding       : 2px;
  white-space   : nowrap;
  vertical-align: middle;
  font-size     : 13px;
  font-weight   : 600;
  color         : #70757a;
  text-align    : center;
  border-top    : 0;
  border-left   : 1px solid #dddddd;
}

.table-month tfoot th {
  padding: 0 !important;
}

.table-month thead th:first-child,
.table-month tfoot th:first-child {
  border-left: 0;
}

.table-month tbody td:first-child {
  font-size  : 10px;
  font-weight: 400;
  color      : #70757a;
  text-align : center;
  padding    : 7px 0 0 0;
  padding    : 7px 0 0 0;
  border-left: 0;
}

.table-month tbody td {
  padding    : 0;
  border-left: 1px solid #dddddd;
  background : repeating-linear-gradient(to bottom, #dddddd, #dddddd 1px, #ffffff 1px, #ffffff 30px);
}

.table-month tbody td>div {
  overflow-y: hidden;
  overflow-x: hidden;
}

.table-month tbody td p {
  height       : 15px;
  margin       : 15px 5px 0 0;
  padding-right: 10px;
  border-left  : 0;
  background   : #ffffff;
  text-align   : right;
}

.bg-th-weekend {
  background: #fafafa;
  border-top: 1px solid #dddddd !important;
}

.bg-weekend {
  background: repeating-linear-gradient(to bottom, #dddddd, #dddddd 1px, #fafafa 1px, #fafafa 30px);
}

@media (max-width: 575px) {
  .table-month thead th .badge {
    width      : 29px;
    height     : 29px;
    font-size  : 14px;
    font-weight: 500;
    padding    : .45rem 0 0 .1rem;
  }
}

/*******************/
/* Calendar Week   */
/*******************/

.table-week thead th {
  padding       : 5px;
  white-space   : nowrap;
  vertical-align: middle;
  font-size     : 11px;
  font-weight   : 600;
  color         : #70757a;
  text-align    : center;
  width         : 13%;
  min-width     : 70px;
  border-top    : 0;
  border-left   : 1px solid #dddddd;
}

.table-week thead th:first-child {
  width      : 9%;
  border-left: 0;
  min-width  : 27px;
}

.table-week thead th .badge {
  width      : 45px;
  height     : 45px;
  font-size  : 22px;
  font-weight: 300;
  padding    : .7rem 0 .7rem .1rem;
  text-align : center;
}

.table-week tbody td {
  padding    : 0;
  border-left: 0;
}

.table-week tbody td>div {
  overflow-y: hidden;
  overflow-x: hidden;
}

/* Semana **********/

.table-week table {
  width       : 100%;
  table-layout: fixed;
}

.table-week table tbody td {
  width      : 13%;
  border-left: 1px solid #dddddd;
  background : repeating-linear-gradient(to bottom, #dddddd, #dddddd 1px, #ffffff 1px, #ffffff 60px);
  min-width  : 61px;
}

.table-week table tbody td:first-child {
  font-size  : 11px;
  font-weight: 400;
  color      : #70757a;
  text-align : center;
  padding    : 7px 0 0 0;
  width      : 9%;
  padding    : 7px 0 0 0;
  min-width  : 28px !important;
  border-left: 0;
}

.table-week table tbody td:nth-child(2),
.table-week table tbody td:last-child {
  background: repeating-linear-gradient(to bottom, #dddddd, #dddddd 1px, #fafafa 1px, #fafafa 60px);
}

.table-week table tbody td p {
  height       : 15px;
  margin       : 45px 10px 0 0;
  padding-right: 10px;
  border-left  : 0;
  background   : #ffffff;
  text-align   : right;
}

@media (max-width: 575px) {
  .table-week thead th {
    min-width    : 35px;
    padding-left : 2px;
    padding-right: 2px;
  }

  .table-week thead th .badge {
    width      : 29px;
    height     : 29px;
    font-size  : 14px;
    font-weight: 500;
    padding    : .45rem 0 0 .1rem;
  }
}

/* Eventos Agenda **********/

.alert-event {
  position     : relative;
  font-size    : 11px;
  line-height  : 10px;
  min-height   : 5px;
  padding      : 1px 3px;
  border       : 1px solid transparent;
  overflow     : hidden;
  text-overflow: ellipsis;
  white-space  : nowrap;
  cursor       : pointer;
}

.event-button:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.event-primary {
  color           : #004085;
  background-color: rgba(0, 123, 255, 0.5);
  border-color    : #007bff;
}

.event-primary:hover {
  background-color: rgba(0, 123, 255, 0.6);
}

.event-secondary {
  color           : #383d41;
  background-color: rgba(90, 98, 104, 0.5);
  border-color    : #5a6268;
}

.event-secondary:hover {
  background-color: rgba(90, 98, 104, 0.6);
}

.event-success {
  color           : #155724;
  background-color: rgba(40, 167, 69, 0.5);
  border-color    : #28a745;
}

.event-success:hover {
  background-color: rgba(40, 167, 69, 0.6);
}

.event-danger {
  color           : #721c24;
  background-color: rgba(220, 53, 69, 0.5);
  border-color    : #dc3545;
}

.event-danger:hover {
  background-color: rgba(220, 53, 69, 0.6);
}

.event-warning {
  color           : #856404;
  background-color: rgba(255, 193, 7, 0.5);
  border-color    : #ffc107;
}

.event-warning:hover {
  background-color: rgba(255, 193, 7, 0.6);
}

.event-info {
  color           : #0c5460;
  background-color: rgba(23, 162, 184, 0.5);
  border-color    : #17a2b8;
}

.event-info:hover {
  background-color: rgba(23, 162, 184, 0.6);
}

/*******************/
/* React Select    */
/*******************/
.css-2b097c-container {
  font-size: 14px;
}

.css-yk16xz-control {
  border       : 1px solid #ced4da;
  border-radius: .25rem;
}

.css-26l3qy-menu {
  margin-top: 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

/*******************/
/* Custom Modal    */
/*******************/
.modal-90w {
  width    : 96% !important;
  max-width: none !important;
}

/*******************/
/* Time Select     */
/*******************/

.horario.required {
  border-left: 4px solid #e12132;
}

.horario .custom-select {
  border-left: 1px solid #ced4da;
}