/* Pages */

.public-page {
  background-color   : #cccccc;
  background         : url(../img/home.jpg) no-repeat center top;
  background-position: center center;
  min-height         : 100% !important;
  background-size    : cover;
}

@media (min-width: 576px) {
  .public-page {
    padding-top: 55px;
  }
}

.full-width {
  width  : 100%;
  padding: 0;
}

.page-wrapper {
  width    : 100%;
  max-width: 798px;
  min-width: 320px;
  padding  : 15px;
  margin   : auto;
}

.login-wrapper {
  width    : 100%;
  max-width: 375px;
  min-width: 320px;
  padding  : 15px;
  margin   : auto;
}

.login-wrapper .form-control {
  position  : relative;
  box-sizing: border-box;
  height    : auto;
  padding   : 10px;
  font-size : 16px;
}

.login-wrapper .form-control:focus {
  z-index: 2;
}

.login-wrapper .top {
  margin-bottom             : -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius : 0;
}

.login-wrapper .middle {
  margin-bottom: -1px;
  border-radius: 0;
}

.login-wrapper .bottom {
  margin-bottom          : 10px;
  border-top-left-radius : 0;
  border-top-right-radius: 0;
}

.btn-fabrica {
  color           : #FFFFFF !important;
  background-color: #fd7922 !important;
  border-color    : #fd7922 !important;
  border-radius   : 0;
}

.btn-fabrica-disabled {
  color        : #fd7922 !important;
  border-color : #fd7922 !important;
  border-radius: 0;
  cursor       : not-allowed;
}

.page-wrapper .form-control.is-invalid,
.page-wrapper .custom-select.is-invalid,
.page-wrapper .was-validated .form-control:invalid {
  border-color: #fd7922 !important;
  background  : none;
}

.page-wrapper .invalid-feedback {
  color  : #fd7922 !important;
  display: block;
}

/*******************/
/* Festival        */
/*******************/
.left-column {
  background         : url(../img/festival.jpg) no-repeat center top;
  background-position: center left;
  background-size    : cover;
  color              : #fff;
}

@media (max-width: 767px) {
  .left-column {
    height: 500px;
  }
}

@media (max-width: 576px) {
  .left-column {
    height: 640px;
  }
}

.full-height,
.full-height>div {
  height   : 100%;
  width    : 100%;
  max-width: 100%;
  min-width: 100%;
  padding  : 0;
  margin   : 0;
}

/*******************/
/* Dropzone        */
/*******************/

.dropzone {
  border       : 2px dashed #fd7922;
  border-radius: 4px;
  cursor       : pointer;
  transition   : height 0.2s ease;
}

.dropzone div {
  text-align: center;
  padding   : 20px;
  margin    : 0;
}

/*******************/
/* Loading overlay */
/*******************/

.loading {
  height: 100%;
}

.loading-overlay {
  /* position        : absolute;
  top             : 0;
  left            : 0; */
  display         : flex;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  height          : 100%;
  opacity         : 0.80;
  filter          : alpha(opacity=80);
  -moz-opacity    : 0.8;
  background-color: #ffffff;
  text-align      : center;
  z-index         : 100;
}

/* .loading-overlay div {
  position: absolute;
  top     : 50%;
} */

/*******************/
/* Datepicker      */
/*******************/
.input-group .react-datepicker-wrapper {
  flex: 1;
}

.form-group .react-datepicker-wrapper {
  width: 100%;
}

.center-triangle .react-datepicker__triangle {
  left: 120px;
}

.right-triangle .react-datepicker__triangle {
  left: 200px;
}